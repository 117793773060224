.card{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height:  65vh;
    position: relative;
    width:  750px;
    height: 60px;
    margin-bottom: 8rem;
}
.facefront{
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    overflow: hidden;
    transition: .5s;
    transform: perspective(600px) rotateY(0deg);
    box-shadow: 5px 5px 10px rgb(235, 227, 227);
}
.faceback{
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    overflow: hidden;
    transition: .5s;
    transform: perspective(600px) rotateY(180deg);
    background: #00838F;
    padding: 15px;
    color: #f3f3f3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 5px 5px 10px rgb(235, 227, 227);
}
.img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.title{
    display: flex;
    align-items: center;
    justify-content: center;
}
.description{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
}
.link{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.icono{
    margin-right: 20px;
    margin-left: 20px;
    grid-row: 4/5;
    grid-column: 1/2;
    justify-self: center;
    width: 2.5rem;
    height: 2.5rem;
    color: #26C6DA;
    cursor: pointer;
}
.icono:hover{
    transform: scale(1.1);
    color: #64FFDA;
   
}
.card:hover .facefront{
    transform: perspective(500px) rotateY(180deg)
}
.card:hover .faceback{
    transform: perspective(500px) rotateY(360deg)
}
