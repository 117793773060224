.type{
    color: white;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.photo{
    margin-top: 7rem;
    margin-bottom: 2rem;
    grid-row: 1/2;
    grid-column: 1/3;
    width: 15rem;
    height: 15rem;
    border-radius: 20rem;
    justify-self: center;
    border: 2px solid #00838F;
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.icono{
    margin-right: 20px;
    margin-left: 20px;
    grid-row: 4/5;
    grid-column: 1/2;
    justify-self: center;
    width: 2.5rem;
    height: 2.5rem;
    color: #00838F;
    cursor: pointer;
}
.icono:hover{
    transform: scale(1.1);
    color: #64FFDA;
   
}
.button{
    margin-top: 2rem;
    margin-bottom: 3rem;
}
.div{
    margin-top: 10rem;
    margin-bottom: 10rem;
}
.contact{
    margin-top: 10rem;
    margin-bottom: 7rem;
}
.footer{
    padding-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #1C1C1C;
    padding-bottom: 2rem;
}